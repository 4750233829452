import React from "react";
import {
  Box,
  makeStyles,
  Typography,
} from "@material-ui/core";
import AddCreditButton from "../../moleculas/creditComparator/AddCreditButton";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F4F6F8",
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  addCreditIcon: {
    marginRight: theme.spacing(1),
  },
  addCreditContainer: {
    padding: theme.spacing(2, 2),
  },
}));

export default function EmptyMordgageCreditDetails() {
  const classes = useStyles();
  return (
    <Box display="flex"
      m={2}
      p={6}

      flexDirection="column"
      justifyContent="center">
      <Box m="auto">
        <Typography variant="subtitle2">
          Nu ai nici o ofertă de credit. Adaugă cel putin 2 oferte ca să le
          poți compara.
          </Typography>
      </Box>
      <Box m="auto" className={classes.addCreditContainer}>
        <AddCreditButton />
      </Box>
    </Box>
  );
}
