import React from "react";
import Seo from "../components/atoms/Seo";
import MortgageCredit from "../components/organisms/mortgageCredit";
import MainLayout from "../components/templates/MainLayout";

export default function CreditComparator() {
  return (
    <MainLayout>
      <Seo
        title={"Comparator de Credite Ipotecare"}
        description={"Comparator de Credite Ipotecare"}
      />
      <MortgageCredit />
    </MainLayout>
  );
}
