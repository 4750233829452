import React from "react";
import { ButtonProps, Fab, makeStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles((theme) => ({
    root: {

    },
    icon: {
        marginRight: theme.spacing(1),
    }

}));

const AddPrimaryButton: React.FC<{ text: string } & ButtonProps> = ({ text, ...props }) => {
    const classes = useStyles();
    return (
        <Fab
            {...props}
            size="small"
            color="secondary"
            variant="extended"
        >
            <AddIcon className={classes.icon} />
            {text}
        </Fab>
    );
};

export default AddPrimaryButton;