import React, { ReactElement, useCallback, useState } from 'react';
import clsx from 'clsx';
import { useSelector, useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import debounce from 'lodash.debounce';

import {
  Box,
  Card, CardActions,
  CardContent,
  Grid,
  IconButton,
  makeStyles,
  Typography,
  Collapse,
  Button,
  TextField,
  InputAdornment
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {
  selectCreditById,
  creditInterestRateChanged,
  TaxType,
  creditRemoved,
  creditNameChanged,
  creditChanged,
} from './mortgageCreditSlice';
import { TaxCollection } from './TaxCollection';
import { RootState } from '../../../app/store';
import theme from '../../atoms/theme';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  expand: {
    // marginLeft: 'auto',
  },
  commonField: {
    // minWidth: '3rem',
    // minWidth: '3rem',
  },
  margins: {
    margin: theme.spacing(3, 2),
  },
  dividerFullWidth: {
    margin: `5px 0 0 ${theme.spacing(2)}px`,
  },
}));

interface DisplayCreditCardProps extends CreditCardProps {
  handleEditButton: () => void;
  expanded: boolean;
}

interface TaxItemGridCollectionProps {
  title: string;
  creditId: string;
  taxType: TaxType;
  unitOfMeasure: string;
}

function TaxItemGridCollection({ title, creditId, taxType, unitOfMeasure }: TaxItemGridCollectionProps) {
  return (
    <Grid item
      xs={12}
      sm={12}
      md={6}
    >
      <TaxCollection
        title={title}
        unitOfMeasure={unitOfMeasure}
        creditId={creditId}
        taxType={taxType}></TaxCollection>
    </Grid>
  );
}

interface ICreditCardInputs {
  creditName: string;
  creditInterestRate: number;
}

const creditCardInputSchema = yup.object({
  creditName: yup.string()
    .required("întroduceți numele ofertei"),

  creditInterestRate: yup.number()
    .typeError("întroduceți un număr")
    .required("întroduceți un număr")
    .min(0, "întroduceți un număr pozitiv"),
});

function DisplayCreditCard({ creditId, className, expanded, handleEditButton }: DisplayCreditCardProps) {
  const credit = useSelector((state: RootState) => selectCreditById(state, creditId));
  const { control, handleSubmit, } = useForm<ICreditCardInputs>(
    {
      mode: "onTouched",
      resolver: yupResolver(creditCardInputSchema),
      defaultValues: {
        creditName: credit?.name,
        creditInterestRate: credit?.interestRate,
      }
    });
  const dispatch = useDispatch();
  const classes = useStyles();

  const onSubmit = (data: ICreditCardInputs) => {
    if (credit) {
      dispatch(creditChanged({ ...credit, name: data.creditName, interestRate: data.creditInterestRate }));
    }
  };

  const handleSubmitFunc = handleSubmit(onSubmit);

  const debouncedSubmit = useCallback(
    debounce(nextValue => {
      nextValue();
    }, 600), []
  );

  const onInputChange = () => {
    debouncedSubmit(handleSubmitFunc);
  };

  return credit ? (
    <Grid
      item
      lg={expanded ? 12 : 3}
      sm={expanded ? 12 : 6}
      xl={expanded ? 12 : 3}
      xs={12}
      style={{
        transition: theme.transitions.create("all", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen
        })
      }}
    >
      <Card className={clsx(classes.root, className)}>
        <CardContent>
          <form>
            <Grid
              container
              
              spacing={3}>
              {expanded ?
                (
                  <Grid item xs={12}>
                    <Button
                      className={classes.expand}
                      startIcon={<ExpandLessIcon />}
                      onClick={handleEditButton}>Inchide</Button>
                  </Grid>) : ('')}
              <Grid item 
                xs={expanded?12:12} 
                sm={expanded?5:12} 
                md={expanded?3:12} 
                lg={expanded?2:12}
                xl={expanded?1:12}
              >
                {!expanded ?
                  (<Typography
                    
                    gutterBottom
                    variant="h5">
                    {credit.name}
                  </Typography>) :
                  (
                    <Controller
                      name="creditName"
                      control={control}
                      render={({ field, fieldState }) => <TextField
                        className={classes.commonField}
                        label="Nume ofertă"
                        variant="outlined"
                        fullWidth
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message}
                        {...field}
                        onChange={e => {
                          field.onChange(e);
                          onInputChange();
                        }}
                      ></TextField>}
                    />
                  )
                }
              </Grid>
       
              <Grid item 
                xs={expanded?12:12} 
                sm={expanded?5:12} 
                md={expanded?3:12} 
                lg={expanded?2:12}
                xl={expanded?1:12}
                >
                <Typography
                  variant="h6"
                >
                  {expanded ?
                    (
                      <Controller
                      name="creditInterestRate"
                      control={control}
                      render={({ field, fieldState }) => <TextField
                        label="Rata dobânzii"
                        variant="outlined"
                        type="number"
                        fullWidth
                        className={classes.commonField}
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message}
                        {...field}
                        onChange={e => {
                          field.onChange(e);
                          onInputChange();
                        }}
                        InputProps={{
                              startAdornment: <InputAdornment position="start">%</InputAdornment>,
                            }}
                      ></TextField>}
                    />
                    )
                    : (`Rata dobânzii: ${credit.interestRate}%`)}
                </Typography>
              </Grid>
            </Grid>
          </form>
        </CardContent>
        {!expanded ? (<CardActions className={classes.actions}>
          <Button
            className={classes.expand}
            startIcon={<ExpandMoreIcon />}
            onClick={handleEditButton}>Deschide</Button>
          <Button
            aria-label="sterge"
            className={classes.expand}
            startIcon={<DeleteIcon />}
            onClick={() => dispatch(creditRemoved(credit.id))}>Sterge</Button>
        </CardActions>) : (<></>)}

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Grid container
              justify="space-between"
              spacing={5}>
              <TaxItemGridCollection
                // className={classes.margins}
                title="Taxe unice"
                creditId={creditId}
                unitOfMeasure="RON"
                taxType={TaxType.OneTimeTax}></TaxItemGridCollection>
              <TaxItemGridCollection
                title="Taxe lunare procentuale"
                unitOfMeasure="%"
                creditId={creditId}
                taxType={TaxType.MonthlyPercentageTax}></TaxItemGridCollection>
              <TaxItemGridCollection
                title="Taxe lunare fixe"
                unitOfMeasure="RON"
                creditId={creditId}
                taxType={TaxType.MonthlyFixedTax}></TaxItemGridCollection>
              <TaxItemGridCollection
                title="Taxe anuale fixe"
                unitOfMeasure="RON"
                creditId={creditId}
                taxType={TaxType.YearlyFixedTax}></TaxItemGridCollection>
            </Grid>
          </CardContent>
          <CardActions className={classes.actions}>
          <Button
            className={classes.expand}
            startIcon={<ExpandLessIcon />}
            onClick={handleEditButton}>închide</Button>
          <Button
            aria-label="sterge"
            className={classes.expand}
            startIcon={<DeleteIcon />}
            onClick={() => dispatch(creditRemoved(credit.id))}>Sterge</Button>
        </CardActions>
        </Collapse>
      </Card>
    </Grid>

  )
    :
    (
      <div>Credit invalid</div>
    );
}

type CreditCardProps = {
  creditId: string,
  className?: string,
};
export function CreditCard({ creditId, className }: CreditCardProps) {
  const [edit, setEdit] = useState(false);

  const handleEditButton = () => {
    setEdit(!edit);
  }

  return edit ?
    (
      <DisplayCreditCard
        creditId={creditId}
        className={className}
        expanded={edit}
        handleEditButton={handleEditButton}
      ></DisplayCreditCard>)
    :
    (
      <DisplayCreditCard
        creditId={creditId}
        className={className}
        expanded={edit}
        handleEditButton={handleEditButton}
      ></DisplayCreditCard>
    );
}