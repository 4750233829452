import { createTextChangeRange } from "typescript";
import { RootState } from "../../../../app/store";
import { range } from "../../../../utils/utils";
import { Tax, TaxType } from "../mortgageCreditSlice";

interface creditPaimentsProps {
  principal: number;
  returnPerMonth: number;
  oneTimeTaxesSum: number;
  yearlyFixedTaxesSum: number;
  monthlyPercentageTaxesSum: number;
  monthlyInterestRate: number;
  monthlyFixedTaxesSum: number;
  fixedMonthlyPayments: boolean;
}
export interface PaimentDetails {
  returnPerMonth: number;
  paid: number;
  numberOfMonthsToPay: number;
  numberOfYearsToPay: number;
  canBePayed: boolean;
}
export function getTotalPaiments({
  principal,
  returnPerMonth,
  oneTimeTaxesSum,
  yearlyFixedTaxesSum,
  monthlyPercentageTaxesSum,
  monthlyInterestRate,
  monthlyFixedTaxesSum,
  fixedMonthlyPayments,
}: creditPaimentsProps): PaimentDetails {
  let remainingPrincipal = principal;
  let paid = oneTimeTaxesSum;
  let numberOfMonthsToPay = 0;

  while (remainingPrincipal > 0) {
    let currentMonthPayments = 0;
    if (numberOfMonthsToPay % 12 === 0) {
      let yearlyAppliedTaxes = yearlyFixedTaxesSum;
      currentMonthPayments = currentMonthPayments + yearlyAppliedTaxes;
    }

    const appliedMonthlyPercentageTaxes =
      remainingPrincipal * (monthlyInterestRate + monthlyPercentageTaxesSum) / 100;
    currentMonthPayments = currentMonthPayments + appliedMonthlyPercentageTaxes + monthlyFixedTaxesSum;
    numberOfMonthsToPay++;
    const principalToPay = fixedMonthlyPayments ? (returnPerMonth - currentMonthPayments) : returnPerMonth;
    if (principalToPay <= 0) {
      return {
        canBePayed: false,
        returnPerMonth: returnPerMonth,
        paid: 0,
        numberOfMonthsToPay: 0,
        numberOfYearsToPay: 0,
      };
    }
    remainingPrincipal = Math.max(remainingPrincipal - principalToPay, 0);
    paid = paid + currentMonthPayments;
  }

  return {
    canBePayed: true,
    returnPerMonth,
    paid,
    numberOfMonthsToPay,
    numberOfYearsToPay: +(numberOfMonthsToPay / 12).toFixed(2),
  };
}

export function getTaxesSum(taxes: Tax[], type: TaxType) {
  const paid = taxes
    .filter((x) => x.type === type)
    .reduce((sum, tax) => sum + tax.value, 0);
  return paid;
}

export interface CreditPaymentsData {
  name: string;
  id: string;
  color: string;
  payments: PaimentDetails[];
}

export const getCreditPaymentsData = (
  state: RootState
): CreditPaymentsData[] => {
  const { credits, creditRange } = state.mortgageCredit;
  const { principal, fixedMonthlyPayments } = state.mortgageCredit.commonFields;
  let returns = range({
    start: creditRange.start,
    stop: creditRange.stop,
    step: creditRange.step,
  });
  
  if(creditRange.maxNumberOfEntires){
    returns = returns.slice(0, creditRange.maxNumberOfEntires);
  }
  
  const creditPaiments = [];

  for (let credit of credits) {
    const oneTimeTaxesSum = getTaxesSum(credit.taxes, TaxType.OneTimeTax);

    const monthlyFixedTaxesSum = getTaxesSum(
      credit.taxes,
      TaxType.MonthlyFixedTax
    );

    const monthlyPercentageTaxesSum = getTaxesSum(
      credit.taxes,
      TaxType.MonthlyPercentageTax
    );

    const yearlyFixedTaxesSum = getTaxesSum(
      credit.taxes,
      TaxType.YearlyFixedTax
    );

    const monthlyInterestRate = +(credit.interestRate / 12).toFixed(4);

    const payments = [];
    for (let returnPerMonth of returns) {
      const paidDetails = getTotalPaiments({
        principal: principal,
        returnPerMonth,
        monthlyFixedTaxesSum,
        monthlyInterestRate,
        monthlyPercentageTaxesSum,
        oneTimeTaxesSum,
        yearlyFixedTaxesSum,
        fixedMonthlyPayments,
      });

      if (paidDetails.canBePayed) {
        payments.push(paidDetails);
      }else{
        //payments.push(paidDetails);
      }
    }

    const creditData = {
      payments: payments,
      name: credit.name,
      color: credit.color,
      id: credit.id,
    };
    creditPaiments.push(creditData);
  }
  return creditPaiments;
};

export const selectCreditsAsVariableReturnRateChart = (state: RootState) => {
  const rawCreditsPayments = getCreditPaymentsData(state);
  const credits = rawCreditsPayments.map((creditPaymentData) => {
    const { id, name, color } = creditPaymentData;
    const payments = creditPaymentData.payments.map((i) => {
      return {
        x: i.returnPerMonth,
        y: i.paid,
        ...i,
        // label: `plata lunara: ${i.returnPerMonth}, total platit: ${i.paid}`
      };
    });
    const labels = creditPaymentData.payments.map(
      (x) => `Rambursare lunara: ${x.returnPerMonth.toFixed(0)} RON, Total platit: ${x.paid.toFixed(0)} RON in ${x.numberOfYearsToPay} ani`
    );
    const maxNumberOfYears = payments.slice(0)[0].numberOfYearsToPay;
    const minNumberOfYears = payments.slice(-1)[0].numberOfYearsToPay;
    return { id, name, color, payments, labels, maxNumberOfYears, minNumberOfYears };
  });

  const maxNumberOfYears = Math.max(...credits.map(x => x.maxNumberOfYears));
  const minNumberOfYears = Math.min(...credits.map(x => x.minNumberOfYears));

  return {
    credits,
    maxNumberOfYears,
    minNumberOfYears,
  }
};
