import Typography from '@material-ui/core/Typography';
import React, { useCallback, useState } from "react";
import { Box, makeStyles, Slider, Tooltip, withStyles } from '@material-ui/core';
import { range } from '../../../utils/utils';
import { CreditRange, selectCreditRange } from './mortgageCreditSlice';
import { useDispatch, useSelector } from 'react-redux';
import { creditRangeChanged } from "./mortgageCreditSlice";
import debounce from 'lodash.debounce';
import { ValueLabelProps } from '@material-ui/core';
import PrettoSlider from '../../atoms/PrettoSlider';

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        [theme.breakpoints.down('xs')]: {
            alignItems: "flex-start",
        },
    },
    slider: {
        width: "50%",
        [theme.breakpoints.down('xs')]: {
            width: "100%",
        },
    },
    sliderContainer: {
        paddingTop: theme.spacing(4),
        [theme.breakpoints.down('xs')]: {
            paddingTop: theme.spacing(5),
        },
    }
}));

function valuetext(value: number) {
    return `${value.toLocaleString()}RON`;
}

const ValueLabelComponent: React.FC<ValueLabelProps> = ({ children, open, value }) => {
    return (
        <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
            {children}
        </Tooltip>
    );
}

const min = 100;
const max = 1000;
const sliderStep = 100;

const ReturnPerMonthSlider: React.FC<{}> = () => {
    const { step, start, stop, maxNumberOfEntires } = useSelector(selectCreditRange);
    const [localSetp, setLocalStep] = useState(step);
    const [localRange, setLocalRange] = useState([start, stop]);

    const classes = useStyles();
    const dispatch = useDispatch();

    const marks = range({ start: min, stop: max, step: sliderStep }).map((value) => ({ label: `${value.toLocaleString()}`, value }));

    const debouncedChange = useCallback(
        debounce((nextValue: CreditRange) => {
            dispatch(creditRangeChanged(nextValue));
        }, 600),
        []
    );

    const handleCreditRangeChanged = (event: any, newValue: number | number[]) => {
        if (typeof newValue === "number") {
            setLocalStep(newValue);
            debouncedChange({ step: Number(newValue), start, stop, maxNumberOfEntires });
        }
    };

    const handleRangeChange = (event: any, newValue: number | number[]) => {
        let c = [2, 3];

        if (typeof newValue !== "number") {
            const [newStart, newStop] = newValue;
            setLocalRange(newValue);
            const newStep = Math.round((newStop - newStart) / 200) * 10;
            debouncedChange({ step: newStep, start: newStart, stop: newStop, maxNumberOfEntires: 0 });
        }
    };

    return (
        <div className={classes.root}>
            <Typography id="monthly-step-slider" gutterBottom>
                Rata lunară în RON
            </Typography>
            {/* <Slider
                className={classes.slider}
                value={localSetp}
                onChange={handleCreditRangeChanged}
                getAriaValueText={valuetext}
                aria-labelledby="monthly-step-slider"
                step={sliderStep}
                marks={marks}
                min={min}
                max={max}
                valueLabelDisplay="auto"
            />
            <Box>
                {step}
            </Box> */}
            <Box width={1} display="flex" flexDirection="column" alignItems="center" className={classes.sliderContainer}>
                <PrettoSlider
                    className={classes.slider}
                    value={localRange}
                    onChange={handleRangeChange}
                    getAriaValueText={valuetext}
                    aria-labelledby="monthly-step-slider"
                    step={50}
                    min={100}
                    max={15000}
                    valueLabelDisplay="on"
                    ValueLabelComponent={ValueLabelComponent}
                />
            </Box>
        </div>
    );
};



export default ReturnPerMonthSlider;