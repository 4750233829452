import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectCommonFields, commonFieldsChanged } from "./mortgageCreditSlice";
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  Card,
  Box,
  CardContent,
  Typography,
  Divider,
  FormControl,
  FormLabel,
  makeStyles,
  Theme,
  createStyles,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import debounce from "lodash.debounce";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",

      backgroundColor: theme.palette.background.default,
    },
    header: {
      margin: theme.spacing(2, 0),
    },
    section1: {
      margin: theme.spacing(2, 0),
    },
    section2: {
      margin: theme.spacing(2, 0, 0, 0),
    },
    section3: {
      //margin: theme.spacing(3, 1, 1),
    },
  })
);

interface ICommonFieldsInput {
  principal: number;
  returnPerMonth: number;
  fixedMonthlyPayments: boolean;
}

const commonFieldsInputSchema = yup.object({
  principal: yup
    .number()
    .typeError("Introduceți valoarea creditului")
    .required("Introduceți valoarea creditului")
    .min(0, "Mai mare ca 0"),
  fixedMonthlyPayments: yup.boolean().required(),
});

export default function CommonFields() {
  const { principal, fixedMonthlyPayments } =
    useSelector(selectCommonFields);

  const { control, handleSubmit } = useForm<ICommonFieldsInput>({
    mode: "onTouched",
    resolver: yupResolver(commonFieldsInputSchema),
    defaultValues: {
      principal,
      fixedMonthlyPayments,
    },
  });

  const classes = useStyles();
  const dispatch = useDispatch();

  const onSubmit = (data: ICommonFieldsInput) => {
    dispatch(commonFieldsChanged({ ...data }));
  };

  const handleSubmitFunc = handleSubmit(onSubmit);

  const debouncedSubmit = useCallback(
    debounce((nextValue) => {
      nextValue();
    }, 600),
    []
  );

  const onChange = () => {
    debouncedSubmit(handleSubmitFunc);
  };

  return (
    <form onSubmit={handleSubmitFunc}>
      <Card>
        <CardContent>
          <Typography
            className={classes.header}
            gutterBottom
            variant="h5"
          >
            Date generale credit
          </Typography>

          <div className={classes.section1}>
            <Grid container spacing={3}>
              <Grid item>
                <Controller
                  name="principal"
                  control={control}
                  defaultValue={principal}
                  rules={{ required: true }}
                  render={({ field, fieldState }) => (
                    <TextField
                      label="Valoarea credit"
                      variant="outlined"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                      required={true}
                      type="number"
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        onChange();
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">RON</InputAdornment>
                        ),
                      }}
                    ></TextField>
                  )}
                />
              </Grid>
            </Grid>
          </div>
          <Divider variant="fullWidth" />
          <div className={classes.section2}>
            <Grid container spacing={3}>
              <Grid item>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Tip rată</FormLabel>
                  <Controller
                    name="fixedMonthlyPayments"
                    control={control}
                    defaultValue={fixedMonthlyPayments}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <RadioGroup
                        aria-label="Tip rată lunară"
                        {...field}
                        value={field.value.toString() == "true"}
                        onChange={(e) => {
                          field.onChange(e);
                          onChange();
                        }}
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio color="primary" />}
                          label="Rată lunară fixă"
                        ></FormControlLabel>
                        <FormControlLabel
                          value={false}
                          control={<Radio color="primary" />}
                          label="Rată lunară descrescătoare"
                        ></FormControlLabel>                        
                      </RadioGroup>
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </div>
        </CardContent>
      </Card>
    </form>
  );
}
