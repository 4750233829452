import { Slider, withStyles } from "@material-ui/core";

const PrettoSlider = withStyles({
    root: {
      height: 8,
    },
    thumb: {
      height: 16,
      width: 16,
      border: '2px solid currentColor',
      marginTop: -6,
      marginLeft: -12,
      '&:focus, &:hover, &$active': {
        boxShadow: 'inherit',
      },
    },
    active: {},
    valueLabel: {
      left: 'calc(-50% - 4px)',
    },
    track: {
      height: 4,
      borderRadius: 4,
    },
    rail: {
      height: 4,
      borderRadius: 4,
    },
  })(Slider);

  export default PrettoSlider;