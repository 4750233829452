import React from "react";
import { useSelector } from "react-redux";
import { selectCreditIds } from "./mortgageCreditSlice";
import { Box, Container, Grid, makeStyles } from "@material-ui/core";
import CommonFields from "./CommonFields";
import EmptyMordgageCreditDetails from "./EmptyMordgageCreditDetails";
import MortgageCreditDetails from "./MortgageCreditDetails";

export function MortgageCredit() {
  const creditIds = useSelector(selectCreditIds);

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <CommonFields></CommonFields>
        </Grid>
      </Grid>

      {creditIds && creditIds.length > 0 ? (
        <MortgageCreditDetails creditIds={creditIds} />
      ) : (
        <EmptyMordgageCreditDetails />
      )}
    </Box>
  );
}
