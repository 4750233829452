import React from "react";
import { CreditCard } from "./CreditCard";
import {
  Box,
  Grid,
  makeStyles,
} from "@material-ui/core";
import ComparatorChart from "./comparatorChart/ComparatorChart";
import AddCreditButton from "../../moleculas/creditComparator/AddCreditButton";
import ReturnPerMonthSlider from "./ReturnPerMonthSlider";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F4F6F8",
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  addCreditIcon: {
    marginRight: theme.spacing(1),
  },
  addButton: {
    display: "flex",
    justifyContent: "flex-end",
    margin: theme.spacing(2, 0),
  },
}));

const MortgageCreditDetails: React.FC<{ creditIds: string[] }> = ({
  creditIds,
}) => {
  const classes = useStyles();

  return (
    <>
      <Grid container spacing={3}>
        {creditIds.map((x) => (
          <CreditCard key={"card-" + x} creditId={x}></CreditCard>
        ))}

        <Grid item xs={12} className={classes.addButton}>
          <AddCreditButton />
        </Grid>
        <Grid item xs={12}>
          <ReturnPerMonthSlider />
        </Grid>
        <Grid item xs={12}>
          <ComparatorChart />
        </Grid>
      </Grid>
    </>
  );
};

export default MortgageCreditDetails;