import React, { useContext } from "react";
import Chart from 'chart.js';
import { useSelector } from "react-redux";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Line } from 'react-chartjs-2';
import { selectCreditsAsVariableReturnRateChart } from "./chartSelectors";
import { Box, Typography, useTheme } from "@material-ui/core";
import SiteConfigContext from "../../../../contexts/siteConfigContext";


export default function ComparatorChart() {
  const { credits, maxNumberOfYears, minNumberOfYears } = useSelector(selectCreditsAsVariableReturnRateChart);
  const theme = useTheme();
  const { lang } = useContext(SiteConfigContext);

  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const longestCreditPriod = credits.reduce((max, next)=>(next.payments.length > max.payments.length ? next : max), credits[0]);
  const data = {
    labels: longestCreditPriod.payments.map(payment => payment.x.toLocaleString(lang, { minimumFractionDigits: 0 })),
    datasets: credits.map(x => ({
      label: x.name,
      fill: false,
      borderColor: x.color,
      lineTension: 0.1,
      data: x.payments.map(({x, y})=>({x:x.toLocaleString(lang, { minimumFractionDigits: 0 }), y})),
    })),
  };

  const options:Chart.ChartOptions = {
    maintainAspectRatio: true,//matches,
    aspectRatio: matches ? 2 : 1,
    responsive: true,
    plugins: {
      // title:{
      //   text: "Total platit la banca in raport cu suma rambursata lunar",
      //   display: true,
      //   font: {
      //     size: 18
      // }
      //},
      tooltip: {
        enabled: true,
        callbacks: {
          title: function (this: Chart.TooltipModel<keyof Chart.ChartTypeRegistry>, tooltipItems: Chart.TooltipItem<keyof Chart.ChartTypeRegistry>[]):string {
            
            const credit = credits[tooltipItems[0].datasetIndex];
            if (!credit) {
              return "";
            }
            return credit.name;
          },
          label: function (context: any) {
            const credit = credits[context.datasetIndex];
            const payment = credit.payments[context.dataIndex];
            const x = payment;
            const numberOfYearsToPay = Math.floor(x.numberOfMonthsToPay / 12);
            const numberOfMonths = x.numberOfMonthsToPay % 12;
            return [
              `Rata lunara ${x.returnPerMonth.toLocaleString(navigator.language, { minimumFractionDigits: 0 })} RON`,
              `Total platit ${x.paid.toLocaleString(navigator.language, { minimumFractionDigits: 0 })} RON`,
              `Rambursare in ${(numberOfYearsToPay === 0 ? '' : numberOfYearsToPay)}${numberOfYearsToPay ? (numberOfYearsToPay === 1 ? ' an ' : ' ani ') : ''}${(numberOfMonths ? (numberOfMonths + (numberOfMonths === 1 ? ' luna' : ' luni')) : '')}`];
          }
        }
      },
    },
    scales: {
      y: {
        title: {
          display: true,
          text: 'Valoarea totală platită băncii (RON)'
        },
      },
      x: {
        title: {
          display: true,
          text: 'Rata lunară (RON)'
        },
        // ticks:{
        //   callback: function(this: any, tickValue: number | string, index: number, ticks: Chart.Tick[]): string{
        //     const label = this.getLabelForValue(tickValue);
        //     const localString = Number(label).toLocaleString(navigator.language, { minimumFractionDigits: 0 });
        //     return localString;
        //   }
        // }
      }
    },
  }

  return (
    <Box>
      <Box mb={2}>
        <Typography
          align="center"
          variant="subtitle2"

        >Total plătit la bancă în raport cu suma rambursată lunar</Typography>
      </Box>
      <Line data={data} options={options} type="line" />
    </Box>
  );
}

interface ComparatorChartV2Props {

}