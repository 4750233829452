interface rangeProps{
    start: number, 
    stop: number, 
    step: number,
}

export function range({start = 0, stop, step = 1}: rangeProps){
    if(start > stop){
        throw new Error('start must be greater than stop :' + {start, stop});
    }
    let values = [];
    let currentValue = start;
    while(currentValue <= stop){
        values.push(currentValue);
        currentValue += step;
    }

    return values;
};