import React from "react";
import { useDispatch } from "react-redux";
import { v4 as uuid } from "uuid";
import AddPrimaryButton from "../../atoms/buttons/AddPrimaryButton";
import { creditAdded } from "../../organisms/mortgageCredit/mortgageCreditSlice";

export default function AddCreditButton(){
    const dispatch = useDispatch();
    return (
        <AddPrimaryButton
          text={"Adaugă ofertă credit"}
          onClick={() => dispatch(creditAdded(uuid()))}
        />
    );
}

