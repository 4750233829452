import React, { useCallback } from 'react';
import { Box, Grid, IconButton, InputAdornment, TextField } from '@material-ui/core';

import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import debounce from 'lodash.debounce';

interface ITaxFieldsInput{
    taxName: string;
    taxValue: number;
};

const taxFieldsInputSchema = yup.object({
    taxName: yup.string(),
    taxValue: yup.number()
      .typeError("Întroduceți un număr")
      .required("Întroduceți un număr")
      .min(0, "Mai mare ca 0"),
  });

export default function Tax({ name, value, unitOfMeasure, onChange, onRemove }: TaxProps) {
    const { control, handleSubmit, } = useForm<ITaxFieldsInput>(
        { 
          mode: "onTouched", 
          resolver: yupResolver(taxFieldsInputSchema),
          defaultValues:{
            taxName: name,
            taxValue: value,
          }
        });
        const dispatch = useDispatch();

        const onSubmit = (data: ITaxFieldsInput) => {
            dispatch(onChange({name: data.taxName, value: data.taxValue}));
          };
        
          const handleSubmitFunc = handleSubmit(onSubmit);
        
          const debouncedSubmit = useCallback(
                debounce(nextValue=>{
              nextValue();
            }, 600), []
            );
          
          const onInputChange = ()=>{
            debouncedSubmit(handleSubmitFunc);
          };

    return (
        <form>
        <Grid container spacing={1}>
            <Grid item xs={7}>
                <Controller
                name="taxName"
                control={control}
                render={({ field, fieldState }) => <TextField
                  fullWidth
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  {...field}
                  onChange={e => {
                    field.onChange(e);
                    onInputChange();
                  }}
                ></TextField>}
                />
            </Grid>

            <Grid item xs={5} >
                <Box component="div"
                    display="flex" >
                    <Box flexGrow={1}>
                    <Controller
                name="taxValue"
                control={control}
                render={({ field, fieldState }) => <TextField
                  fullWidth
                  type="number"
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  {...field}
                  onChange={e => {
                    field.onChange(e);
                    onInputChange();
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">{unitOfMeasure}</InputAdornment>,
                }}>
                

                </TextField>}
                />
                        {/* <TaxInput value={value} unitOfMeasure={unitOfMeasure} onChange={value => onChange({ name, value })}></TaxInput> */}
                    </Box>
                    <IconButton component="span" aria-label="delete" onClick={onRemove}>
                        <RemoveCircleOutlineIcon />
                    </IconButton>
                </Box>

            </Grid>
        </Grid>
        </form>
    );
}

interface FieldChanged {
    name: string;
    value: number;
}

type OnRemove = () => void;
interface TaxProps {
    name: string,
    value: number,
    unitOfMeasure: string
    onChange: (props: FieldChanged) => void;
    onRemove?: OnRemove;
}