import { Box, Button, Divider, Fab, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import { RootState } from '../../app/store';
import { EditableField } from './EditableField';
import { v4 as uuid } from 'uuid';

import {
  taxChanged,
  taxRemoved,
  taxAdded,
  TaxType,
  selectTaxesByType,
} from './mortgageCreditSlice';
import Tax from './Tax';

const useStyles = makeStyles((theme) => ({
  dividerFullWidth: {
    // margin: `5px 0 0 ${theme.spacing(2)}px`,
    padding: theme.spacing(1, 0)
  },
  addBtn: {
    display: "flex",
    justifyContent: "flex-end",
  }
}));

type TaxCollectionProps = {
  creditId: string;
  taxType: TaxType;
  unitOfMeasure: string;
  title: string;
  className?: string;
};
export function TaxCollection({ creditId, taxType, unitOfMeasure, title, className }: TaxCollectionProps) {
  const taxes = useSelector((state: RootState) => selectTaxesByType(state, creditId, taxType));
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <div className={className}>
      <Divider></Divider>
      <Typography
        className={classes.dividerFullWidth}
        color="textSecondary"
        display="block"
        variant="caption"
      >
        {title}
      </Typography>
      <Box >
        {taxes.map(x => <Tax
          key={'field-' + x.id}
          value={x.value}
          unitOfMeasure={unitOfMeasure}
          name={x.name}
          onChange={e => dispatch(taxChanged({ creditId, taxId: x.id, name: e.name, value: e.value }))}
          onRemove={() => dispatch(taxRemoved({ creditId, taxId: x.id }))}
        ></Tax>)}
        <Box className={classes.addBtn}>
          <Button
            variant="contained"
            size="small"
            onClick={() => dispatch(taxAdded({ creditId, taxId: uuid(), type: taxType }))}

            startIcon={<AddIcon />}>
            Adaugă taxă
           </Button>
        </Box>
      </Box>
      <div>

      </div>
    </div>
  )
}